import { useTranslations } from "next-intl";

export default function SimpleButton ({text, translation, onClickEvent, dark}: {text: string, translation: string, onClickEvent: () => void,  dark?: boolean}) {
  const t = useTranslations(translation);

  return (
    <button onClick={onClickEvent} className={`${dark ? 'text-[#112211]' : 'text-white'} text-[20px] py-[10px] px-6 hover:bg-primary rounded-[12px]`}>
      {t(text)}
    </button>
  )
}