'use client'
import { useTranslations } from "next-intl";
import Image from "next/image";

export default function NextAccomodationCard({ title, description, image, url } : { title: string, description: string, image : string, url : string}) {
  return (
    <a href={url}
        target='_blank' className='relative w-full hover:scale-110 transition-transform duration-300 ease-in-out p-1'>
      <div 
        className='rounded-[12px] bg-white w-full h-full flex flex-col z-10 relative'
        style={{ boxShadow: '2px 4px 16px 0px rgba(17, 34, 17, 0.10)' }}
      >

        <div className="h-[160px] relative">
            <Image src={image} 
               layout="fill"
               objectFit="cover"
               objectPosition="center" className='rounded-t-[12px]' alt='' />
        </div>
        
        <div className="p-4">
          <p className='font-[TradeGothicBold] mb-4 text-start my-2'>{title}</p>
          <p className='text-[#121] font-medium text-[20px]'>{description}</p>
        </div>
      </div>
    </a>
  )
}