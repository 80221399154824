'use client'
import FallCard from "@components/atoms/FallCard";
import useRequest from "@hooks/useRequest";
import { useTranslations } from "next-intl"
import { Fragment, useEffect, useState } from "react";
import { FallType } from "types/Fall.type";

export default function Fall() {
  const t = useTranslations('fall');
  const [fall, setFall] = useState([]);
  const toastConfig: any = {
    position: "top-right",
    autoClose: 15000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const { request: getFallProperties } = useRequest({
    url: '/categories/fall-into-travel',
    method: "get",
    onSuccess: (response: any) => {
      setFall(response.data.data)
    },
    onError: (error: any) => {
    },
  });

  useEffect(() => {
    getFallProperties()
  }, [])

  return (
    <section className='mb-20 mt-[100px] lg:mt-0 max-w-[1300px] md:pb-60 lg:pb-0'>
      <p className='text-[32px] text-black font-semibold mb-4'>{t('title')}</p>
      <p className='text-[20px] text-black mb-10'>{t('description')}</p>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-y-8 gap-x-4 xl:gap-y-0'>
        {
          fall.map((fall: FallType, index) => {
            return(
              <Fragment key={index}>
                <FallCard {...fall} />
              </Fragment>
            )
          })
        }
      </div>
    </section>
  )
}