'use client'
import LandingSearchBar from "@components/organisms/LandingSearchBar";
import Navbar from "@components/molecules/Navbar";
import NavbarMobile from "@components/molecules/NavbarMobile";
import { useIsMobile } from "common/hooks/useIsMobile";
import { useTranslations } from "next-intl";
import Image from "next/image";

export default function Header() {
  const t = useTranslations('home.header');
  const isMobile = useIsMobile();
  
  return (
    <section className='w-full flex flex-col items-center'>
      <div 
        className='w-full max-w-[1300px] relative' 
      >
        {
          isMobile ? <NavbarMobile /> : <Navbar />
        }
        <Image 
          src='/img/header-bg.webp' 
          alt='Header background image' 
          fill 
          priority 
          objectFit={'contain'} 
          className='!relative !top-0 md:!-top-[5px] rounded-[13px] md:rounded-[26px]'
        />
        <div 
          className='w-full absolute top-[33px] pt-10 md:pt-0 md:top-7 z-10 flex flex-col justify-center align-center rounded-[13px] md:rounded-[26px]'
          style={{
            background: 'linear-gradient(270deg, rgba(5, 16, 54, 0.5) 0%, rgba(30, 53, 47, 0.85) 72.43%)',
            height: 'calc(100% - 32px)'
          }}
        >
          <h2 className='text-white'>{t('helping')}</h2>
          <h1 className='text-white'>{t('title')}</h1>
          <h3 className='text-white'>{t('subtitle')}</h3>
        </div>
      </div>

      <div className='sm:-translate-y-[10%] lg:-translate-y-1/3 xl:-translate-y-1/2 w-[90%] max-w-[1170px] relative z-10 md:z-30'>
        <LandingSearchBar />
      </div>
    </section>
  )
}