import { useTranslations } from "next-intl";

export default function OutlineButton ({text, translation, onClickEvent, dark}: {text: string, translation: string, onClickEvent: () => void, dark?: boolean}) {
  const t = useTranslations(translation);

  return (
    <button onClick={onClickEvent} className={`${ dark ? 'bg-primary text-white' : 'bg-white text-black'} rounded-[8px] text-[20px] py-[10px] px-6 hover:text-white hover:bg-primary`}>
      {t(text)}
    </button>
  )
}