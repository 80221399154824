'use client'
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useState } from "react";

export default function ReviewCard({ title, description, name, rating, image } : { title: string, description: string, name: string, rating: number, image : string}) {
  const t = useTranslations('reviews');
  const [showMore, setShowMore] = useState(false);

  const trimDescription = (inputString: string) => {
    const words = inputString.match(/\b\w+\b/g);
  
    if (words && words.length > 0) {
      const first10Words = words.slice(0, 13);
      return first10Words.join(' ');
    } else {
      return inputString;
    }
  }

  return (
    <div className='relative w-full'>
      <div 
        className='rounded-[20px] bg-white p-6 w-full h-full flex flex-col justify-between z-10 relative'
        style={{ boxShadow: '2px 4px 16px 0px rgba(17, 34, 17, 0.10)' }}
      >
        <div>
          <h3 className='font-[TradeGothicBold] mb-4 text-start'>“{title}”</h3>
          <p className={`text-[#121] font-medium text-[20px] mb-3 ${showMore && 'show-from-above-animation'}`}>{showMore ? description : trimDescription(description) + '...'}</p>
          <button onClick={() => setShowMore(!showMore)} className='font-[TradeGothicBold] mb-4 text-end text-[20px] w-full'>{!showMore ? t('viewMore') : t('viewLess')}</button>
        </div>
        
        <div>
          <div className={`w-[${rating * 20}%] flex gap-3 mb-5`}>
            <Image src='/img/star.svg' width={24} height={24} alt='Rating' />
            <Image src='/img/star.svg' width={24} height={24} alt='Rating' />
            <Image src='/img/star.svg' width={24} height={24} alt='Rating' />
            <Image src='/img/star.svg' width={24} height={24} alt='Rating' />
            <Image src='/img/star.svg' width={24} height={24} alt='Rating' />
          </div>
          <p className='font-[TradeGothicBold] text-[20px] mb-10'>{name}</p>
        </div>

        <Image src={image} width={200} height={100} objectFit="cover" className='w-full h-[200px]' alt='' />
      </div>

      <div 
        className='rounded-[20px] bg-[#33484386] p-6 translate-y-6 translate-x-6 absolute top-0 w-full h-full'
      ></div>
    </div>
  )
}