'use client'
import { useTranslations } from "next-intl"
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export default function AccountDropdown({ userName }: {userName: string}) {

  const t = useTranslations('account');
  const router = useRouter();
  const [image, setImage] = useState('');

  const menuItems = [
    {
      name: t('myAccount'),
      link: '/account?tab=0',
      icon: '/img/user.svg'
    },
    {
      name: t('reservations'),
      link: '/account?tab=1',
      icon: '/img/rooms.svg'
    },
    {
      name: t('wishlist'),
      link: '/account?tab=2',
      icon: '/img/wishlist-full.svg'
    },
  ]

  useEffect(() => {
    const profileImage = localStorage.getItem('profileImage') || ''
    setImage(profileImage)
  }, [])

  const handleLogOut = () => {
    localStorage.setItem("token", '');
    router.push('/login')
  }

  return (
    <section 
      className='absolute top-16 right-0 p-8 rounded-[12px] bg-white z-40 w-[300px] show-from-above-animation' 
      style={{ boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.05)' }}
    >
      <div className='flex items-center'>
        <div className='rounded-full w-[55px] h-[55px] relative mr-2 overflow-hidden'>
          <Image src={image || '/img/user.webp'} alt='User profile image' fill style={{ objectFit: 'cover' }} />
        </div>
        <p className='text-black text-[20px] font-semibold'>{userName}</p>
      </div>

      <div className="w-full h-[0.5px] bg-darkGreen my-6"></div>

      {
        menuItems.map((menuItem) => {
          return (
            <a href={menuItem.link} key={menuItem.name} className='flex w-full justify-between items-center my-2'>
              <div className='flex items-center'>
                <Image src={menuItem.icon} width={16} height={16} alt='Account icon' className='mr-2' />
                <p className='text-[20px] text-[#121] font-medium'>{menuItem.name}</p>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M6 4L10.5 8.5L6 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
          )
        })
      }

      <div className="w-full h-[0.5px] bg-darkGreen my-6"></div>
      <div onClick={() => handleLogOut()} className='flex items-center justify-start cursor-pointer'>
        <Image src='/img/logout.svg' width={16} height={16} alt='Logout' className='mr-2' />
        <p className='text-[20px] text-[#121] font-medium'>{t('logout')}</p>
      </div>
    </section>
  )
}