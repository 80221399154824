"use client";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { LocationType } from "types/Fall.type";

export default function FallCard({
  title,
  locations,
  image,
  id,
  minPrice,
}: {
  title: string;
  locations: LocationType[];
  image: string;
  id: number;
  minPrice: number;
}) {
  const t = useTranslations("fall");
  const router = useRouter();

  const handleBook = () => {
    const searchLink = `/property/${id}?checkIn=null&checkOut=null&guests=0&children=0`;
    router.push(searchLink);
  };

  return (
    <div className="w-full relative rounded-[12px] overflow-hidden h-[350px]">
      <div className="h-full w-full min-w-[350px] relative">
        <Image
          src={image}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          alt="Location main image"
        />
      </div>
      <div
        className="flex flex-col justify-between absolute bottom-0 w-full p-6"
        style={{
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5) 83%, rgba(0,212,255,0) 100%)",
          minHeight: "150px",
        }}
      >
        <div>
          <p className="text-[20px] font-semibold text-white">{title}</p>
          <div className="flex">
            <Image
              src="/img/destination.svg"
              width={16}
              height={16}
              alt="Location icon"
              style={{ filter: "brightness(0) invert(1)" }}
            />
            <p className="text-[20px] ml-1 text-white">{locations[0]?.name}</p>
          </div>
        </div>
        <button
          onClick={() => handleBook()}
          className="main-button !w-full mt-4 !justify-center !bg-salmon !text-black"
        >
          {t("book")}
        </button>
      </div>
    </div>
  );
}
