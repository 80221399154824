"use client";

import { useTranslations } from "next-intl";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useRequest from "@hooks/useRequest";

export default function ListPropertyForm() {
  const t = useTranslations("listProperty");
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    propertyName: '',    
    location: '',
  })
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');;
  const toastConfig: any = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  }

  const checkAllFieldAreCompleted = () => {
    let userDataFields: any = {...userData}
    //remove optional fields from check
    const { propertyName, location, ...rest } = userDataFields;
    userDataFields = rest;

    for (const key in userData) {
      if (userDataFields.hasOwnProperty(key) && (userDataFields[key] === undefined || userDataFields[key] === null || userDataFields[key] === '')) {
        toast.error(t('allFields'), {...toastConfig, toastId: 'all-fileds-contact'});
        return false; 
      }
    }

    return true
  }

  const checkEmail = () => {
    const testEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const isEmailValid = testEmailFormat.test(userData.email);
    if (!isEmailValid) {
      toast.error(t('validEmail'), toastConfig);
      return false;
    }

    return true;
  }

  const checkPhoneNumber = () => {
    const testEmailFormat = /^\+?\d+$|^\+?\d*$/;
    const isEmailValid = testEmailFormat.test(userData.phoneNumber);
    if (!isEmailValid) {
      toast.error(t('validPhone'), toastConfig);
      return false;
    }

    return true;
  }

  const checkName = () => {
    if(userData.name.length < 4) {
      toast.error(t('validName'), toastConfig);
      return false
    }

    return true
  }

  const handleChange = (field: string, value: string) => {
    const tempUserData = { ...userData, [field]: value}
    setUserData(tempUserData);
  };

  const { request: contact } = useRequest({
    url: `/property-management-contact`,
    method: "post",
    onSuccess: (response: any) => {
      setUserData({
        name: '',
        email: '',
        phoneNumber: '',
        propertyName: '',
        location: ''
      })
      toast.success(t('success'), {...toastConfig, icon: () =>  <img src="/img/check.svg" />});
    },
    onError: (error: any) => {
      if( error.response.data.error === 'VALIDATION_FAILED') {
        toast.error(t('invalid'), toastConfig);
      } else toast.error(t('wrong'), toastConfig);
    },
  });

  const handleSubmit = async () => {
    if (!checkAllFieldAreCompleted() || !checkEmail() || !checkName() || !checkPhoneNumber()) {
      return;
    }
    
    let token = '';
    if (executeRecaptcha) {
      token = await executeRecaptcha('contact');
      setToken(token)
      await contact({ body: { ...userData, token } });
    } else console.log('Execute recaptcha not yet available');
  };

  return (
    <section>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6'>
        <div>
          <div className="bg-white text-[#1C1B1F] text-[20px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("name")}
          </div>
          <input
            value={userData.name}
            onChange={(e) => handleChange('name', e.target.value)}
            placeholder=""
            maxLength={100}
            className="border border-solid border-inputBorder text-black w-full py-[18px] px-6 rounded-[4px]"
          />
        </div>

        <div>
          <div className="bg-white text-[#1C1B1F] text-[20px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("email")}
          </div>
          <input
            value={userData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            placeholder=""
            maxLength={100}
            className="border border-solid border-inputBorder text-black w-full py-[18px] px-6 rounded-[4px]"
          />
        </div>

        <div>
          <div className="bg-white text-[#1C1B1F] text-[20px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("phoneNumber")}
          </div>
          <input
            value={userData.phoneNumber}
            onChange={(e) => handleChange('phoneNumber', e.target.value)}
            placeholder=""
            maxLength={50}
            className="border border-solid border-inputBorder text-black w-full py-[18px] px-6 rounded-[4px]"
          />
        </div>

        <div>
          <div className="bg-white text-[#1C1B1F] text-[20px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("propertyName")}
          </div>
          <input
            value={userData.propertyName}
            onChange={(e) => handleChange('propertyName', e.target.value)}
            placeholder=""
            maxLength={50}
            className="border border-solid border-inputBorder text-black w-full py-[18px] px-6 rounded-[4px]"
          />
        </div>
      </div>

      <div className="pt-5">
          <div className="bg-white text-[#1C1B1F] text-[20px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("location")}
          </div>
          <input
            value={userData.location}
            onChange={(e) => handleChange('location', e.target.value)}
            placeholder=""
            maxLength={50}
            className="border border-solid border-inputBorder text-black w-full py-[18px] px-6 rounded-[4px]"
          />
      </div>

      <button
        onClick={() => handleSubmit()}
        className="main-button !w-full justify-center mt-10 mb-4"
      >
        {t("title")}
      </button>
    </section>
  );
}