"use client";
import OutlineButton from "@components/atoms/OutlinedButton";
import SimpleButton from "@components/atoms/SimpleButton";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useEffect, useState } from "react";
import AccountDropdown from "../AccountDropdown";

export default function Navbar() {
  const t = useTranslations("common.navbar");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  const handleLogin = () => {
    window.location.href = "/login";
  };

  const handleSignUp = () => {
    window.location.href = "/register";
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      const firstName = localStorage.getItem("userFirstName") as string;
      const lastName = localStorage.getItem("userLastName") as string;
      setUserName(firstName + " " + lastName);
    }
  }, []);

  return (
    <nav className="flex justify-between items-center pt-12 pb-6 px-12 w-full -mb-24 !z-50 relative">
      <div>
        <a href='/property-management' target='_blank' className='text-white flex items-center'>
          <Image src='/img/rooms.svg' alt='Property management' width={20} height={20} style={{ filter: 'brightness(0) invert(1)' }}/>
          <span className='text-white ml-1'>
            {t('propertyManagement')}
          </span>
        </a>
      </div>

      <Image
        src="/img/logo-white.svg"
        alt="Logo white"
        width={200}
        height={50}
      />

      {isLoggedIn ? (
        <div className='relative'>
          <div onClick={() => setShowAccountDropdown(!showAccountDropdown)} className="flex items-center cursor-pointer">
            <p className='text-[20px] text-salmon font-medium'>{t('hello')}, &nbsp;</p>
            <p className="text-white text-[20px] font-semibold">{userName}</p>
          </div>

          {
            showAccountDropdown && <AccountDropdown userName={userName} />
          }
        </div>
      ) : (
        <div className="flex items-center">
          <div className="mr-4">
            <SimpleButton
              text={"login"}
              translation={"common.navbar"}
              onClickEvent={() => handleLogin()}
            />
          </div>
          <OutlineButton
            text={"signUp"}
            translation={"common.navbar"}
            onClickEvent={() => handleSignUp()}
          />
        </div>
      )}
    </nav>
  );
}
